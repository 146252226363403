/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import pixImg from 'assets/logo_pix.svg';
import overAgeBgo from 'assets/idadeBgo.png';
import pnrLogo from 'assets/logo_palpite.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Antillephone from 'plugins/Antillephone';
import { makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { LanguageMenu } from 'components/languageMenu/languageMenu';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
  labelFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& img': {
      marginBottom: theme.spacing(1),
      position: 'relative',
      top: -10,
      width: '100%',
      height: 'auto',
      maxWidth: 80,
    },
    [theme.breakpoints.down('sm')]: {
      '& img': {
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
      },
    },
  },
  footer: {
    color: theme.palette.grey[400],
    paddingTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    // justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      textAlign: 'center',
    },
  },
  linksSOcialMidias: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const Footer = ({ t }) => {
  const classes = useStyles();
  const location = useLocation();
  const { media } = useDeviceInfo();
  return (
    <Box
      bgcolor={'background.footer'}
      py={8}
      id='footer'
      className={
        media === 'mobile' &&
        (location.pathname === '/caletaGames' ||
          location.pathname === '/every-matrix' ||
          location.pathname === '/vibraGames' ||
          location.pathname.includes('/cassinoGames'))
          ? 'tw-hidden'
          : 'tw-block'
      }
    >
      <Container className='tw-mb-16 md:tw-mb-0'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <img src={pnrLogo} alt='PNR' height={'75px'} />
            <Box display={'flex'} mt={4}>
              <Box mr={2} color='grey.400'>
                <a
                  className={classes.linksSOcialMidias}
                  href='https://www.facebook.com/palpitenarede/?locale=pt_BR'
                >
                  <FacebookIcon />
                </a>
              </Box>
              <Box color='grey.400'>
                <a
                  className={classes.linksSOcialMidias}
                  href='https://www.instagram.com/palpitenarede/'
                >
                  <InstagramIcon />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <FooterList
              title={t('footer.menu.site.title')}
              options={[
                {
                  title: t('footer.menu.site.howWorks'),
                  route: '/como-funciona',
                },
                { title: t('footer.menu.site.questions'), route: '/duvidas' },
                { title: t('footer.menu.site.rules'), route: '/regras' },
                // { title: t('footer.menu.site.promotion'), route: '/promocao' },
              ]}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FooterList
              title={t('footer.menu.links.title')}
              options={[
                {
                  title: t('footer.menu.links.liveBingo'),
                  route: '/live-bingo-bg', // old '/bingo-ao-vivo'
                },
                { title: t('footer.menu.links.slot'), route: '/slots' },
                {
                  title: t('footer.menu.links.videoBingo'),
                  route: '/video-bingo',
                },
                {
                  title: t('footer.menu.links.popularLottery'),
                  route: '/loteria-popular',
                },
              ]}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FooterList
              title={t('footer.menu.company.title')}
              options={[
                {
                  title: t('footer.menu.company.legislation'),
                  route: '/legislacao',
                },
                {
                  title: t('footer.menu.company.securityPrivacy'),
                  route: '/seguranca-e-privacidade',
                },
                {
                  title: t('footer.menu.company.responsibleGame'),
                  route: '/jogo-responsavel',
                },
                {
                  title: t('footer.menu.company.disputeResolution'),
                  route: '/disputa-de-resolucao',
                },
                {
                  title: t('footer.menu.company.servicesTerms'),
                  route: '/termos-de-servico',
                },
                {
                  title: t('footer.menu.company.accountsPayoutsBonuses'),
                  route: '/accounts-payouts-and-bonuses',
                },
                {
                  title: t('footer.menu.company.antiMoneyLaudering'),
                  route: '/anti-money-laudering',
                },
                {
                  title: t('footer.menu.company.policiesPrivacy'),
                  route: '/policies-privacy',
                },
                {
                  title: t('footer.menu.company.selfExclusion'),
                  route: '/self-exclusion',
                },
              ]}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FooterList
              title={t('footer.menu.aboutUs.title')}
              options={[
                {
                  title: t('footer.menu.aboutUs.whoWeAre'),
                  route: '/quem-somos',
                },
              ]}
            />
          </Grid>
        </Grid>
        <Box bgcolor='grey.400' style={{ opacity: 0.2 }}>
          <Divider />
        </Box>
        <Box py={10}>
          <div className='tw-flex tw-justify-between md:tw-px-36 tw-px-8 tw-items-center'>
            <img src={pixImg} alt='pix' className='tw-h-11 tw-w-auto' />
            <img src={overAgeBgo} alt='pix' height={'60px'} />
          </div>
        </Box>
        <Box bgcolor='grey.400' style={{ opacity: 0.2 }}>
          <Divider />
        </Box>
        <Box py={10} className={classes.labelFooter}>
          <Grid container alignItems='center'>
            <Grid item xs={12} sm={2}>
              <Antillephone />
            </Grid>
            <Grid item xs={12} sm>
              <Box color='grey.400'>
                <Typography>
                  {t('footer.menu.warningFooter.warning')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box bgcolor='grey.400' style={{ opacity: 0.2 }}>
          <Divider />
        </Box>
        <Box
          color='grey.400'
          className={classes.footer}
          pt={10}
          // display={'flex'}
          // justifyContent={'center'}
        >
          <Typography>{t('footer.menu.warningFooter.rights')}</Typography>
          <LanguageMenu withText />
        </Box>
      </Container>
    </Box>
  );
};

const FooterList = ({ title, options = [] }) => {
  return (
    <List>
      <ListItem>
        <Box
          fontWeight='box.fontWeight'
          fontSize='box.fontSize'
          color='grey.400'
        >
          <Typography className='!tw-font-semibold'>{title}</Typography>
          <Box mb={3} />
        </Box>
      </ListItem>
      {/*eslint-disable-next-line arrow-parens*/}
      {options.map(opt => {
        return (
          <ListItem component={Link} to={opt.route ?? ''} key={opt.title}>
            <Box fontSize='body2.fontSize' color='grey.400'>
              <Typography>{opt.title}</Typography>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

FooterList.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
};

Footer.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(Footer);
